<template>
  <div class="monitoring">
    <!-- 成型过程智能监控 -->
    <div class="content">
      <h1 id="znjk">注塑过程智能监控</h1>

      <div class="second-nav">
        <ul class="second-level-directory" id="second-level-directory">
          <div class="nav-box">
            <li class="nav-link" v-for="nav,index in nav_data" :key="index">
              <a :href="nav.href">{{ nav.name }}</a>
            </li>
          </div>
        </ul>
      </div>

      <div style="height: 30px"></div>
      <div id="introduction" style="margin-top: 50px;">
        <h1 style="text-align: center;">行业痛点</h1>
        <div style="height: 50px"></div>
        <div class="industry-introduction">
          <div class="pain-point-analysis" v-for="item, index in analysis_info" :key="index">
            <h3>{{item.title}}</h3>
            <span>{{item.desc}}</span>
            <div style="height: 45px"></div>
            <img :src="item.img_url" alt="" width="160px">
          </div>
        </div>
      </div>
    </div>

    <div class="core-function" id="corefunction">
      <h1>核心功能</h1>
      <div class="fun-content">
        <ul class="nav">
          <li
            v-for="(nav, index) in nav_info"
            :key="index"
            @click="handleNav(index)"
            :class="{ active: index == activeIndex }"
          >
            {{ nav.title }}
          </li>
        </ul>
        <div style="height: 40px"></div>
        <div class="img">
          <div v-for="(item, index) in nav_info" :key="index" class="myBox">
            <div class="myImg">
              <img :src="item.img_url" alt="" width="1200px">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      analysis_info: [
        { title: '分布不均', desc: '型腔间差异', img_url: require("@/assets/images/product/monitoring/xqcy.png") },
        { title: '随机干扰', desc: '模次间差异', img_url: require("@/assets/images/product/monitoring/mccy.png") },
        { title: '系统干扰', desc: '批次间差异', img_url: require("@/assets/images/product/monitoring/pccy.png") },
      ],
      nav_info: [
        { title: '制品、材料对型腔压力曲线影响', desc: '', img_url: require('@/assets/images/product/monitoring/fun1.png') },
        { title: '成型过程可视化', desc: '', img_url: require('@/assets/images/product/monitoring/fun2.png') },
        { title: '工艺参数优化', desc: '', img_url: require('@/assets/images/product/monitoring/fun3.png') },
        { title: '成型过程监控', desc: '', img_url: require('@/assets/images/product/monitoring/fun4.png') },
        { title: '工况自动识别', desc: '', img_url: require('@/assets/images/product/monitoring/fun5.png') },
        { title: '工艺参数智能调节', desc: '', img_url: require('@/assets/images/product/monitoring/fun6.png') },
      ],
      activeIndex: 0,
      nav_data: [
        { name: "行业痛点", href: '#introduction' },
        { name: "核心功能", href: '#corefunction' },
      ]
    }
  },
  mounted() {
    document.addEventListener('scroll', this.handleWheel)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.handleWheel);
  },
  methods: {
    handleNav(index) {
      this.activeIndex = index;
      var box = document.querySelectorAll(".myBox");
      for (let i = 0; i < box.length; i++) {
        box[i].style.transform = `translateX(${index * -1200}px)`;
        box[i].style.transition = "transform 0.5s ease-in-out";
      }
    },
    handleWheel() {
      var targetUrl = document.getElementById('second-level-directory')
      var znjk = document.getElementById('znjk')
      var introduction = document.getElementById('introduction')
      var distanceToTop = znjk.getBoundingClientRect().top;
      const topOffset = introduction.getBoundingClientRect().top;

      if (distanceToTop < 141) {
        targetUrl.style.position = 'fixed'
        targetUrl.style.top = '90px'
      } else if (distanceToTop == 141) {
        targetUrl.style.top = '200px'
      }

      const updateLinkStyles = function(index) {
        const lis = document.querySelectorAll(".nav-link")
        lis.forEach((li, i) => {
          if (i === index) {
            li.style.borderBottom = '2px solid red';
          } else {
            li.style.borderBottom = 'none';
          }
        })
      }

      if (topOffset <= 304 && topOffset > -251) {
        updateLinkStyles(0);
      } else if (topOffset <= -251) {
        updateLinkStyles(1);
      }
    },
  }
};
</script>

<style lang="less" scoped>
.industry-introduction {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .pain-point-analysis {
    width: 270px;
    height: 320px;
    padding: 20px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    box-shadow: 0 4px 12px rgab(0, 0, 0, 0.04);
    h3 {
      text-align: center;
      margin-bottom: 20px;
    }
    h3:hover {
      color: rgb(215, 6, 6);
    }
    span {
      display: block;
      font-size: 15px;
      color: #999999;
      line-height: 26px;
      text-align: center;
    }
    img {
      margin-left: 50%;
      transform: translate(-50%);
    }
  }
}

.fun-content {
  .nav {
    margin: 0 auto;
    width: 1100px;
    display: flex;
    justify-content: space-around;
    li {
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }
  }
  .img {
    width: 1200px;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    margin-left: 50%;
    transform: translate(-50%);
    border-radius: 35px;
    background: white;
    box-sizing: border-box;
    .myImg {
      display: flex;
    }
  }
}
#introduction, #corefunction {
  scroll-margin-top: 200px;
}
</style>